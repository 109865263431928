<template>
  <div class="all-content sms-pay-success">
    <div class="content-wrap bg-white content-row" style="height: 100%; top: 0">
      <div class="confirm-list regist-card-success">
        <p style="justify-content: flex-end">
          <img src="/image/character_hat_sad.svg" alt="캐릭터">
        </p>
        <p class="comment">
          <em class="t-c black-title mt-10">결제 실패</em>
          <em class="t-c mt-10" v-if="$route.params.message === ''">유효한 url이 아닙니다.<br/>다시 한번 시도하세요.</em>
          <em class="t-c mt-10" v-else>{{ $route.params.message }}</em>
        </p>
        <div class="w-90">
          <p class="info mt-10"></p>
          <p class="info mt-10">
            <span class="title">결제일시</span>
            <span class="cardInfo">{{ new Date() | moment('YY.MM.DD HH:mm:ss') }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RentPayError"
}
</script>

<style scoped>

</style>
