import moment from "moment";

export default Object.freeze({
    install(Vue) {
        // 토스트 스타일 알림
        Vue.prototype.notify = function (type, msg, is_alert=false) {
            if(is_alert) {
                alert(msg)
            } else {
                if(type === 'error') {
                    window.$('#alert-wrap').append("<div class=\"alert-content bg-red\"><span>"+msg+"</span></div>")
                } else {
                    window.$('#alert-wrap').append("<div class=\"alert-content\"><span>"+msg+"</span></div>")
                }
                setTimeout(function() {
                    window.$('#alert-wrap div:first-child').remove()
                }, 3000)
            }
        }

        // 기본 HTTP 에러 처리
        Vue.prototype.httpError = function (data) {
            console.log(data)
            if(data.state === 401) {
                sessionStorage.removeItem('refreshToken')
                sessionStorage.removeItem('accessToken')

                this.$router.push({ name: 'Intro' }).catch(() => {})
            } else {
                if (data.message && data.message.length > 0) {
                    this.notify('error', data.message, false)
                } else if (data.error.length > 0) {
                    let message = data.error[0].message
                    this.notify('error', message, false)
                }
            }
            // if(Array.isArray(data.error)) {
            //     for(let i=0; i<data.error.length; i++) {
            //         this.notify('error', data.error[i].message, false)
            //     }
            // } else {
            //     if(data.state === 401) {
            //         if(data.error === '해당 액세스 토큰의 유효기간이 만료되었음') {
            //             sessionStorage.removeItem('refreshToken')
            //             sessionStorage.removeItem('accessToken')
            //
            //             // this.$router.push({ name: 'Login', params: { msg: '해당 액세스 토큰의 유효기간이 만료되었습니다.' } }).catch(() => {})
            //             this.$router.push({ name: 'Login'}).catch(() => {})
            //         }
            //
            //         sessionStorage.removeItem('refreshToken')
            //         sessionStorage.removeItem('accessToken')
            //
            //         if(!localStorage.getItem('passToken')) {
            //             this.$router.push({ name: 'Login', params: { msg: '로그인이 필요한 서비스입니다.' } }).catch(() => {})
            //         }
            //         this.$router.push({ name: 'Login' }).catch(() => {})
            //     } else {
            //         this.notify('error', data.error, false)
            //     }
            // }
        }

        // 디바이스 푸시 갱신
        Vue.prototype.refreshPush = function () {
            if(window.getVersion()) {
                let device = ''
                if (window.getDeviceType() === 'ios') {
                    device = 'I'
                } else if (window.getDeviceType() === 'android') {
                    device = 'A'
                }
                let formData = new FormData()
                formData.append('fcmToken', window.getFCMToken()?window.getFCMToken():'')
                formData.append('os', device)

                this.$put(this.$DOMAIN, this.$USER_FCM_UPDATE, 'refreshPush', formData, () => {
                    //
                }, () => {
                    this.notify('error', '푸시 서비스 등록에 문제가 발생했습니다.')
                })
            }
        }

        //토픽 설정
        Vue.prototype.setPushAlarm = function (callback) {
            this.$get(this.$DOMAIN, this.$USER_INFO, 'login_getPush', (result) => {
                let alarm = result.data
                alarm.noticeAlarm ? window.subscribedTopic(process.env.VUE_APP_TOPIC_NOTICE) : window.unsubscribedTopic(process.env.VUE_APP_TOPIC_NOTICE)
                callback()
            }, (result) => {
                this.httpError(result.data)
                callback()
            })
        }

        // 토큰 갱신
        Vue.prototype.refreshTokenRate = 5*60*1000
        Vue.prototype.refreshToken = function () {
            let refreshToken = sessionStorage.getItem('refreshToken')
            if(!refreshToken) {
                return
            }

            let formData = new FormData()
            formData.append('refreshToken ', refreshToken)

            this.$post(this.$DOMAIN, this.$AUTH_REFRESHTOKEN, 'refreshToken', formData, (result) => {
                sessionStorage.setItem('accessToken', result.data.accessToken)
                sessionStorage.setItem('refreshToken', result.data.refreshToken)
                if(localStorage.getItem('passToken')) {
                    localStorage.setItem('passToken', result.data.passToken)
                }

                setTimeout(() => {
                    this.refreshToken()
                }, this.refreshTokenRate)
            }, (result) => {
                this.httpError(result.data)

                sessionStorage.removeItem('accessToken')
                sessionStorage.removeItem('refreshToken')
            })
        }

        Vue.prototype.calendarOptions = {
            format: 'YYYY.MM.DD',
            locale: moment.locale('ko'),
            icons: {
                time: 'glyphicon glyphicon-time',
                date: 'glyphicon glyphicon-calendar',
                up: 'icon-arrow-up',
                down: 'icon-arrow-down',
                previous: 'icon-arrow-left',
                next: 'icon-arrow-right',
                today: 'glyphicon glyphicon-screenshot',
                clear: 'glyphicon glyphicon-trash',
                close: 'glyphicon glyphicon-remove'
            },
            ignoreReadonly: true,
        }

        Vue.filter('numberWithCommas', function (x) {
            if (x.toString().split(".").length === 2) {
                let parts = x.toString().split(".")
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                parts[1] = parts[1].substr(0, 4)
                return parts.join('.')
            } else {
                return (x+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
        })
        Vue.filter('numberWithCommasAll', function (x) {
            if (x.toString().split(".").length === 2) {
                let parts = x.toString().split(".")
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                if (parts[1].length > 8) {
                    parts[1] = parts[1].substr(0, 7) + '...'
                } else {
                    parts[1] = parts[1].substr(0, 8)
                }
                return parts.join('.')
            } else {
                return (x+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
        })

        Vue.filter('cardNumberMasking', function (_cardNumber) {
            if (_cardNumber.length === 16){
                let firstNumber = _cardNumber.substr(0, 4)
                let lastNumber = _cardNumber.substr(8, 4)
                let newCardNumber = firstNumber + '****' + lastNumber

                return newCardNumber

            } else {
                return _cardNumber
            }
        })

        Vue.filter('formatDate', function(value) {
            if (value) {
                return moment(String(value)).format('YY.MM.DD')
            }
        });

        // 비밀번호 정규식 체크
        Vue.prototype.validatePw = function (password) {
            let reg_pw = /(?=.*[0-9])(?=.*[a-zA-Z])(?=.*?[`~!@@#$%^&*|₩₩₩'₩";:₩/?]).{8,20}/

            if (reg_pw.test(password)){ // 비밀번호 정규식체크
                return true
            }
            return false
        }

        // 이메일 정규식 체크
        Vue.prototype.validateEmail = function (email) {
            if ((/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(email))) {
                return true
            }
            return false
        }

    }
})
