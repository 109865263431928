<template>
  <div class="all-content bg-white sms-pay">
    <header class="header-wrap">
      <p>페이허브 결제</p>
    </header>
    <div class="content-wrap" style="height: auto">
      <div class="bar-10"></div>
      <div class="main-wrap">
        <div class="charge-form pt-0">
          <p class="mb-15 fz-18">상품 정보</p>
          <p class="fz-16 color-gray lh-36">{{ info.content ? info.content : '없음' }}</p>
        </div>
      </div>
      <div class="bar-10"></div>
      <div class="main-wrap">
        <div class="charge-form pt-0">
          <p class="mb-15 fz-18">결제정보</p>
          <p class="fz-16 color-gray lh-36">판매금액<span class="item-name color-pink">{{ info.amount | numberWithCommas }}원</span></p>
          <p class="fz-16 color-gray lh-36">할부<span class="item-name color-pink">{{ info.installment === '00' ? '일시불' : (info.installment * 1) + '개월' }}</span></p>
        </div>
      </div>
      <div class="bar-10"></div>
      <div class="main-wrap t-c">
        <vue-qrcode :value="qrStr" style="width: 250px;"/>
        <p><button v-show="!isMobile" class="btn btn-m btn-pink" @click="openReappay(qrStr)">립페이 앱으로 결제</button></p>
      </div>
      <div class="main-wrap">
        <p class="color-darkgray fz-12">
          (주)페이허브 | 대표 이정환<br/>
          서울특별시 송파구 오금로35길16, 2층(오금동,신신빌딩)<br/>
          사업자등록번호 673-86-01311 | 대표번호 1661-0447<br/>
        </p>
      </div>
      <div class="align-bottom d-flex-row w-100">
        <button class="btn btn-m btn-pink w-50per" @click="paymentDone(true)">결제완료</button>
        <button class="btn btn-m btn-gray w-50per" @click="window.close()">취소</button>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'

export default {
  name: "AuthPayment",
  components: {
    VueQrcode,
  },
  data() {
    return {
      info: {
        "idx":'',
        "shoppingMallIdx":'',
        "authPaymentTid":"",
        "amount":'',
        "content":"",
        "installment":"00",
        "returnUrl":"",
        "expirationDate":"",
        "createAt":""
      },
      qrStr: '',
      isMobile: false,
    }
  },
  mounted() {
    if (this.$route.params.qr) {
      this.getInfo(this.$route.params.qr)
    } else {
      this.paymentDone({'error':'결제 정보를 불러올수 없습니다.'})
    }

    let userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1 || userAgent.indexOf('ipod') > -1 || userAgent.indexOf('android') > -1) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }

    /*test qr code*/
    let qrStrInfo = {
      url : {
        domain: this.$DOMAIN_AUTHPAY,
        info: this.$AUTHPAY_QRINFO,
        payment: this.$AUTHPAY_PAYMENT,
      },
      data: {
        qrCode: 'qfqwfqwfqwqf'
      }
    }
    this.qrStr = JSON.stringify(qrStrInfo)
  },
  methods: {
    getInfo(_qr) {
      console.log(_qr)
      let data = {
        "qrCode":_qr
      }
      this.$post(this.$DOMAIN_AUTHPAY, this.$AUTHPAY_QRINFO, this.$options.name + '_getInfo', JSON.stringify(data), (result) => {
        console.log(result)
        this.info = result.data
        let qrStrInfo = {
          url : {
            domain: this.$DOMAIN_AUTHPAY,
            info: this.$AUTHPAY_QRINFO,
            payment: this.$AUTHPAY_PAYMENT,
          },
          data: {
            qrCode: _qr
          }
        }
        this.qrStr = JSON.stringify(qrStrInfo)
      }, (result) => {
        this.httpError(result.data)
      })

    },
    paymentDone(result) {
      window.opener.postMessage(
          // 전달할 data (부모창에서 호출할 함수명)
          {
            functionName : 'authPayment',
            result : result
          }
          // 부모창의 도메인
          // , 'http://abc.com'
          // 모든 도메인에 대하여 허용 ( 추천하지 않음 )
          , '*'
      );
      window.close()
    },
    openReappay(_qr) {
      let userAgent = navigator.userAgent.toLowerCase()
      if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1 || userAgent.indexOf('ipod') > -1) {
        window.location.href = 'reappayment://?message=payment&data=' + _qr
      } else if (userAgent.indexOf('android') > -1) {
        window.location.href = 'intent://reappayment?message=payment&data=' + _qr + '#Intent;scheme=reappay;package=com.reappay.reappay;end';
      } else {
        this.notify('error', '지원되지않는 디바이스 입니다.', false)
      }
    }
  }
}
</script>

<style scoped>
</style>
