var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all-content bg-white sms-pay"},[_c('header',{staticClass:"header-wrap"},[_c('button',{staticClass:"btn-back",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'SMSUserIntro' })}}},[_c('i',{staticClass:"icon-back"})]),_c('p',[_vm._v("결제 정보")])]),_c('div',{staticClass:"content-wrap",staticStyle:{"height":"auto"}},[_c('div',{staticClass:"main-wrap",staticStyle:{"padding-bottom":"14px"}},[_c('div',{staticClass:"charge-form pt-0"},[_c('p',{staticClass:"fz-18 shop-name"},[_c('img',{staticClass:"mr-10 bag-img",attrs:{"src":"/image/bag.svg","alt":"가방"}}),_vm._v(_vm._s(_vm.info.shopName)),_c('img',{staticClass:"fr phone-img",attrs:{"src":"/image/phone.svg","alt":"폰"},on:{"click":_vm.callShop}})])])]),_c('div',{staticClass:"bar-10"}),_c('div',{staticClass:"main-wrap"},[_c('div',{staticClass:"charge-form pt-0"},[_c('p',{staticClass:"mb-15 fz-18"},[_vm._v("판매자 메모")]),_c('p',{staticClass:"fz-16 color-gray lh-36"},[_vm._v(_vm._s(_vm.info.memo ? _vm.info.memo : '없음'))])])]),_c('div',{staticClass:"bar-10"}),_c('div',{staticClass:"main-wrap"},[_c('div',{staticClass:"charge-form pt-0"},[_c('p',{staticClass:"mb-15 fz-18"},[_vm._v("결제정보")]),_c('p',{staticClass:"fz-16 color-gray lh-36"},[_vm._v("상품명"),_c('span',{staticClass:"item-name"},[_vm._v(_vm._s(_vm.info.goodsName))])]),_c('p',{staticClass:"fz-16 color-gray lh-36"},[_vm._v("판매금액"),_c('span',{staticClass:"item-name color-pink"},[_vm._v(_vm._s(_vm._f("numberWithCommas")(_vm.info.amount))+"원")])])])]),_c('div',{staticClass:"bar-10"}),_vm._m(0),_vm._m(1),_c('button',{staticClass:"align-bottom btn btn-m btn-pink",on:{"click":function($event){return _vm.$router.push({
              name: 'SMSUserPayCard',
              params: {
                amount: _vm.info.amount,
                additionType: _vm.info.additionType,
                token: _vm.token,
                type: _vm.info.manualPaymentType
              }
            })}}},[_vm._v("다음 ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-wrap"},[_c('p',{staticClass:"color-darkgray fz-12"},[_vm._v("결제나라는 통신판매중개자이며, 통신판매의 당사자가 아닙니다."),_c('br'),_vm._v("상품, 상품정보, 거래에 관한 의무와 책임은 판매자에게 있습니다.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-wrap"},[_c('p',{staticClass:"color-darkgray fz-12"},[_vm._v(" (주)페이허브 | 대표 이정환"),_c('br'),_vm._v(" 서울특별시 송파구 오금로35길16, 2층(오금동,신신빌딩)"),_c('br'),_vm._v(" 사업자등록번호 673-86-01311 | 대표번호 1661-0447"),_c('br')])])}]

export { render, staticRenderFns }