<template>
  <div class="all-content sms-pay-success">
    <div class="content-wrap bg-white content-row" style="height: 100%; top: 0">
      <div class="confirm-list regist-card-success">
        <p style="justify-content: flex-end">
          <img src="/image/character_happy.svg" alt="캐릭터">
        </p>
        <p class="comment">
          <em class="t-c black-title color-pink">{{ amount | numberWithCommas }}원</em>
          <em class="t-c black-title color-pink mt-0">결제 완료</em>
          <em class="t-c mt-10">결제가 완료되었습니다.</em>
        </p>
        <div class="w-90">
          <p class="info"></p>
          <p class="info mt-10">
            <span class="title">결제일시</span>
            <span class="cardInfo">{{ new Date() | moment('YY.MM.DD HH:mm:ss') }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RentPaySuccess"
}
</script>

<style scoped>

</style>