<template>
  <div class="all-content bg-white sms-pay">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.push({ name: 'SMSUserIntro' })"><i class="icon-back"></i>
      </button>
      <p>결제 정보</p>
    </header>
    <div class="content-wrap" style="height: auto">
      <div class="main-wrap" style="padding-bottom: 14px">
        <div class="charge-form pt-0">
          <p class="fz-18 shop-name"><img class="mr-10 bag-img" src="/image/bag.svg" alt="가방"/>{{ info.shopName }}<img
              class="fr phone-img" src="/image/phone.svg" alt="폰" @click="callShop"/></p>
        </div>
      </div>
      <div class="bar-10"></div>
      <div class="main-wrap">
        <div class="charge-form pt-0">
          <p class="mb-15 fz-18">판매자 메모</p>
          <p class="fz-16 color-gray lh-36">{{ info.memo ? info.memo : '없음' }}</p>
        </div>
      </div>
      <div class="bar-10"></div>
      <div class="main-wrap">
        <div class="charge-form pt-0">
          <p class="mb-15 fz-18">결제정보</p>
          <p class="fz-16 color-gray lh-36">상품명<span class="item-name">{{ info.goodsName }}</span></p>
          <p class="fz-16 color-gray lh-36">판매금액<span class="item-name color-pink">{{
              info.amount | numberWithCommas
            }}원</span></p>
        </div>
      </div>
      <div class="bar-10"></div>
      <div class="main-wrap">
        <p class="color-darkgray fz-12">결제나라는 통신판매중개자이며, 통신판매의 당사자가 아닙니다.<br>상품, 상품정보, 거래에 관한 의무와 책임은 판매자에게 있습니다.</p>
      </div>
      <div class="main-wrap">
        <p class="color-darkgray fz-12">
          (주)페이허브 | 대표 이정환<br/>
          서울특별시 송파구 오금로35길16, 2층(오금동,신신빌딩)<br/>
          사업자등록번호 673-86-01311 | 대표번호 1661-0447<br/>
        </p>
      </div>
      <button class="align-bottom btn btn-m btn-pink"
              @click="$router.push({
                name: 'SMSUserPayCard',
                params: {
                  amount: info.amount,
                  additionType: info.additionType,
                  token: token,
                  type: info.manualPaymentType
                }
              })">다음
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SMSUserPayInfo",

  data() {
    return {
      token: this.$route.params.token,
      info: {
        shopName: '',
        shopHp: '',
        memo: '',
        goodsName: '',
        taxType: '',
        amount: 0,
        additionType: '',
        sendTime: '',
        manualPaymentType: ''
      }
    }
  },
  methods: {
    callShop() {
      if (this.info.shopHp !== '') {
        location.href = "tel:" + this.info.shopHp
      }
    },
  },
  created() {
    this.$get(this.$DOMAIN, this.$PAYMENT_SMS + '?token=' + this.token, 'get_payment_sms', (result) => {
      this.info = result.data
    }, (e) => {
      let message = ''
      if (e.data && e.data.message) {
        message = e.data.message
      }
      this.$router.push({name: 'SMSPayError', params: {message: message}})
    })
  }
}
</script>

<style scoped>
.bag-img {
  width: 20px;
}

.phone-img {
  width: 40px;
  background-color: #EC758B;
  padding: 10px;
  border-radius: 20px;
}
</style>
