import Vue from 'vue'
import App from './App.vue'
import router from './router'

router.beforeEach((to, from, next) => {
  let tokenMiddle = [ // 로그인후 이용가능한 화면 추가
    //
  ]

  if(tokenMiddle.indexOf(to.name) !== -1) {
    let token = sessionStorage.getItem('accessToken')
    if(!token) {
      next({ name: 'BeforeLogin' })
    }
  }

  next()
})

Vue.config.productionTip = false

import api from './commonjs/api';
Vue.use(api)

import common from "@/commonjs/common";
Vue.use(common)

import event from './commonjs/event';
Vue.use(event)

import vueMoment from 'vue-moment'
Vue.use(vueMoment)

import {filter} from './commonjs/filter'
Vue.use({filter})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
